import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/bible-groups.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  &:empty {
    display: none;
  }
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    text-align: center;
  }
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Bible Groups"
    SecondText="Pray, Study the Word of God, & Do life together."
  >
    <div
      css={css`
        display: flex;
        justify-items: center;
      `}
    >
      <MainButton href="https://pdfhost.io/v/QvbY4tauVk_1_Corinthians_13_Final">
        Bible Group Homework
      </MainButton>
    </div>
    <SEO title="Bible Groups" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      What Are Bible Groups?
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      At Cross Church, we believe spiritual growth and connection are vital. We
      also believe that there are certain things that you can only learn in
      relation to others. For that reason, we challenge every person to be
      involved in a Bible Group. All our Bible Groups do three essential things:
      Pray, Study the Word of God, & Do life together.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Get Plugged In
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      To learn more about our Bible Groups, fill out the form below and we will
      get in touch with you.
    </ParagraphText>
    <iframe
      src="https://cccares.formstack.com/forms/biblegroups"
      title="Bible Groups"
      width="100%"
      height="1080px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default IndexPage
